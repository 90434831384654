<template>
  <div class="range-container" :id="id">
    <ul class="range-labels">
      <div
        style="width: auto;"
        v-for="(range, index) in rangeData"
        :key="range.name"
      >
        <li
          @click="selectThumb(index + 1)"
          :class="[
            createName(range.name),
            theme,
            { active: index + 1 == inputRange }
          ]"
        >
          <p>{{ range.name }}</p>
        </li>
      </div>
    </ul>
    <div class="range">
      <input
        v-model.number="inputRange"
        type="range"
        min="1"
        :max="rangeAmount"
        steps="1"
      />
    </div>
  </div>
</template>
<script>
export default {
  props: [`rangeData`, `theme`, `id`],
  data() {
    return {
      inputRange: null,
      rangeStyle: ``
    };
  },
  computed: {
    rangeAmount() {
      return this.rangeData.length;
    },
    value() {
      return this.inputRange ? this.rangeData[this.inputRange - 1].value : null;
    }
  },
  watch: {
    rangeData() {
      this.createCss();
      this.inputRange = null;
    },
    inputRange() {
      this.$emit("changed", this.value, this.id);
    }
  },
  mounted() {
    this.createCss();
  },
  methods: {
    selectThumb(numbers) {
      this.inputRange = numbers;
    },
    createCss() {
      var prevelem = document.getElementById(`rangeStyle${this.id}`);
      if (prevelem) {
        prevelem.remove();
      }
      var head = document.head || document.getElementByTagName("head")[0];
      var css = document.createElement("style");
      css.setAttribute("id", `rangeStyle${this.id}`);
      css.type = "text/css";
      var styles = "";
      var color_stops = "";
      var self = this;
      var no = 1;
      var label = `.range-container .range-labels li.rangeBullet`;
      var boxShadow = `0 0 0 3px`;
      var boxShadowActive = `0 0 0 6px`;
      this.rangeData.forEach(val => {
        styles += `
            ${label}${self.sluggify(val.name)}::after {
              -webkit-box-shadow: ${boxShadow} ${val.color};
              -ms-box-shadow: ${boxShadow} ${val.color};
              -o-box-shadow: ${boxShadow} ${val.color};
              box-shadow: ${boxShadow} ${val.color};
            }

            ${label}${self.sluggify(val.name)}.active::after {
              -webkit-box-shadow: ${boxShadowActive} ${val.color};
              -ms-box-shadow: ${boxShadowActive} ${val.color};
              -o-box-shadow: ${boxShadowActive} ${val.color};
              box-shadow: ${boxShadowActive} ${val.color};
            }
        `;
        var comma = this.rangeAmount == no ? "" : ",";
        color_stops += val.color + comma;
        no++;
      });

      var prefix = `#${this.id} .range input[type=range]`;
      var gradient = `
        background: ${this.rangeData[0].color};
        background: -moz-linear-gradient(left, ${color_stops});
        background: -webkit-linear-gradient(left,${color_stops});
        background: linear-gradient(to right, ${color_stops});
        filter: progid:DXprogid:DXImageTransform.Microsoft.gradient(startColorstr='${
          this.rangeData[0].color
        }', endColorstr='${
        this.rangeData[this.rangeAmount - 1].color
      }',GradientType=1);
      `;
      styles += `
        ${prefix}::-webkit-slider-runnable-track{${gradient}}
        ${prefix}:focus::-webkit-slider-runnable-track {${gradient}}
        ${prefix}::-moz-range-track {${gradient}}
        ${prefix}::-ms-track {background: transparent;}
        ${prefix}::-ms-fill-lower {${gradient}}
        ${prefix}:focus::-ms-fill-lower {${gradient}}
        ${prefix}::-ms-fill-upper {${gradient}}
        ${prefix}:focus::-ms-fill-upper {${gradient}}
      `;
      css.appendChild(document.createTextNode(styles));
      head.appendChild(css);
    },
    sluggify(str) {
      str = str.replace(/^\s+|\s+$/g, ""); // trim
      str = str.toLowerCase();

      // remove accents, swap ñ for n, etc
      var from = "àáãäâèéëêìíïîòóöôùúüûñç·/_,:;";
      var to = "aaaaaeeeeiiiioooouuuunc------";

      for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
      }

      str = str
        .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
        .replace(/\s+/g, "-") // collapse whitespace and replace by -
        .replace(/-+/g, "-"); // collapse dashes

      return str;
    },
    createName(name) {
      return "rangeBullet" + this.sluggify(name);
    }
  }
};
</script>

<style lang="scss">
$color_1: #0093ff;
$color_2: #ffffff;
$color_3: #ffffff;

$track-color: #eceff1 !default;
$thumb-color: transparent !default;

$thumb-radius: 50% !default;
$thumb-height: 30px !default;
$thumb-width: 30px !default;
$thumb-shadow-size: 0 !default;
$thumb-shadow-blur: 0 !default;
$thumb-shadow-color: rgba(0, 0, 0, 0) !default;
$thumb-border-width: 0 !default;
$thumb-border-color: #eceff1 !default;

$track-width: 100% !default;
$track-height: 8px !default;
$track-shadow-size: 0 !default;
$track-shadow-blur: 0 !default;
$track-shadow-color: rgba(0, 0, 0, 0) !default;
$track-border-width: 0 !default;
$track-border-color: #cfd8dc !default;

$track-radius: 0 !default;
$contrast: 0 !default;

@mixin css3-prefix($property, $value) {
  -webkit-#{$property}: #{$value};
  -khtml-#{$property}: #{$value};
  -moz-#{$property}: #{$value};
  -ms-#{$property}: #{$value};
  -o-#{$property}: #{$value};
  #{$property}: #{$value};
}
@mixin flexbox() {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

@mixin flex($fg: 1, $fs: null, $fb: null) {
  $fg-boxflex: $fg;

  @if type-of($fg) == "list" {
    $fg-boxflex: nth($fg, 1);
  }

  -webkit-box-flex: $fg-boxflex;
  -webkit-flex: $fg $fs $fb;
  -moz-box-flex: $fg-boxflex;
  -moz-flex: $fg $fs $fb;
  -ms-flex: $fg $fs $fb;
  flex: $fg $fs $fb;
}

@mixin flex-direction($direction) {
  -webkit-flex-direction: $direction;
  -moz-flex-direction: $direction;
  -ms-flex-direction: $direction;
  flex-direction: $direction;
}

@mixin flex-wrap($wrap) {
  -webkit-flex-wrap: $wrap;
  -moz-flex-wrap: $wrap;
  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;
}

@mixin flex-flow(
  $values: (
    row nowrap
  )
) {
  // No Webkit Box fallback.
  -webkit-flex-flow: $values;
  -moz-flex-flow: $values;
  -ms-flex-flow: $values;
  flex-flow: $values;
}

@mixin order($val) {
  -webkit-box-ordinal-group: $val;
  -moz-box-ordinal-group: $val;
  -ms-flex-order: $val;
  -webkit-order: $val;
  order: $val;
}

@mixin flex-grow($grow) {
  -webkit-flex-grow: $grow;
  -moz-flex-grow: $grow;
  -ms-flex-grow: $grow;
  flex-grow: $grow;
}

@mixin flex-shrink($shrink) {
  -webkit-flex-shrink: $shrink;
  -moz-flex-shrink: $shrink;
  -ms-flex-shrink: $shrink;
  flex-shrink: $shrink;
}

@mixin flex-basis($width) {
  -webkit-flex-basis: $width;
  -moz-flex-basis: $width;
  -ms-flex-basis: $width;
  flex-basis: $width;
}

@mixin justify-content($value: flex-start) {
  @if $value == flex-start {
    -webkit-box-pack: start;
    -ms-flex-pack: start;
  } @else if $value == flex-end {
    -webkit-box-pack: end;
    -ms-flex-pack: end;
  } @else if $value == space-between {
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
  } @else if $value == space-around {
    -ms-flex-pack: distribute;
  } @else {
    -webkit-box-pack: $value;
    -ms-flex-pack: $value;
  }
  -webkit-justify-content: $value;
  -moz-justify-content: $value;
  justify-content: $value;
}

@mixin align-items($value: stretch) {
  @if $value == flex-start {
    -webkit-box-align: start;
    -ms-flex-align: start;
  } @else if $value == flex-end {
    -webkit-box-align: end;
    -ms-flex-align: end;
  } @else {
    -webkit-box-align: $value;
    -ms-flex-align: $value;
  }
  -webkit-align-items: $value;
  -moz-align-items: $value;
  align-items: $value;
}

@mixin align-self($value: auto) {
  // No Webkit Box Fallback.
  -webkit-align-self: $value;
  -moz-align-self: $value;
  @if $value == flex-start {
    -ms-flex-item-align: start;
  } @else if $value == flex-end {
    -ms-flex-item-align: end;
  } @else {
    -ms-flex-item-align: $value;
  }
  align-self: $value;
}

@mixin align-content($value: stretch) {
  // No Webkit Box Fallback.
  -webkit-align-content: $value;
  -moz-align-content: $value;
  @if $value == flex-start {
    -ms-flex-line-pack: start;
  } @else if $value == flex-end {
    -ms-flex-line-pack: end;
  } @else {
    -ms-flex-line-pack: $value;
  }
  align-content: $value;
}

@mixin track {
  cursor: pointer;
  height: $track-height;
  transition: all 0.2s ease;
  width: $track-width;
}

@mixin thumb {
  @include shadow($thumb-shadow-size, $thumb-shadow-blur, $thumb-shadow-color);
  background: $thumb-color;
  border: $thumb-border-width solid $thumb-border-color;
  border-radius: $thumb-radius;
  cursor: pointer;
  height: $thumb-height;
  width: $thumb-width;
}

@mixin box-shadow(
  $x: 2px,
  $y: 2px,
  $blur: 5px,
  $color: rgba(0, 0, 0, 0.4),
  $spread: "",
  $inset: ""
) {
  @if ($inset != "") {
    @include css3-prefix("box-shadow", $inset $x $y $blur $spread $color);
  } @else {
    @include css3-prefix("box-shadow", $x $y $blur $spread $color);
  }
}

@mixin shadow($shadow-size, $shadow-blur, $shadow-color) {
  box-shadow: $shadow-size $shadow-size $shadow-blur $shadow-color,
    0 0 $shadow-size lighten($shadow-color, 5%);
}

@mixin font-styles(
  $family: false,
  $size: false,
  $colour: false,
  $weight: false,
  $lh: false
) {
  @if $family != false {
    font-family: $family;
  }
  @if $size != false {
    font-size: $size;
  }
  @if $colour != false {
    color: $colour;
  }
  @if $weight != false {
    font-weight: $weight;
  }
  @if $lh != false {
    line-height: $lh;
  }
}

@mixin transition($properties...) {
  @if length($properties) >= 1 {
    @include css3-prefix("transition", $properties);
  } @else {
    @include css3-prefix("transition", "all 0.2s ease-in-out 0s");
  }
}

.range-container {
  @include flexbox();
  @include flex-flow(column nowrap);
  @include justify-content(center);
  @include align-items(center);
  @include transition(all 400ms);
  margin: 25px 0 20px;

  .range {
    @include flexbox();
    @include flex-flow(column nowrap);
    @include justify-content(center);
    @include align-items(center);
    position: relative;
    width: 84%;
    height: 4px;

    input[type="range"] {
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      margin: $thumb-height / 2 0;
      width: $track-width;

      &:focus {
        outline: 0;

        &::-webkit-slider-runnable-track {
          background: lighten($track-color, $contrast);
        }

        &::-ms-fill-lower {
          background: $track-color;
        }

        &::-ms-fill-upper {
          background: lighten($track-color, $contrast);
        }
      }

      &::-webkit-slider-runnable-track {
        @include track;
        @include shadow(
          $track-shadow-size,
          $track-shadow-blur,
          $track-shadow-color
        );
        background: $track-color;
        border: $track-border-width solid $track-border-color;
        border-radius: $track-radius;
      }

      &::-webkit-slider-thumb {
        @include thumb;
        -moz-appearance: none;
        -webkit-appearance: none;
        appearance: none;
        margin-top: ((-$track-border-width * 2 + $track-height) / 2) -
          ($thumb-height / 2);
        z-index: 2;
        opacity: 1;
      }

      &::-moz-range-track {
        @include track;
        @include shadow(
          $track-shadow-size,
          $track-shadow-blur,
          $track-shadow-color
        );
        background: $track-color;
        border: $track-border-width solid $track-border-color;
        border-radius: $track-radius;
      }

      &::-moz-range-thumb {
        @include thumb;
        z-index: 2;
        opacity: 1;
      }

      &::-ms-track {
        @include track;
        background: transparent;
        border-color: transparent;
        border-width: ($thumb-height / 2) 0;
        color: transparent;
      }

      &::-ms-fill-lower {
        @include shadow(
          $track-shadow-size,
          $track-shadow-blur,
          $track-shadow-color
        );
        background: darken($track-color, $contrast);
        border: $track-border-width solid $track-border-color;
        border-radius: $track-radius * 2;
      }

      &::-ms-fill-upper {
        @include shadow(
          $track-shadow-size,
          $track-shadow-blur,
          $track-shadow-color
        );
        background: $track-color;
        border: $track-border-width solid $track-border-color;
        border-radius: $track-radius * 2;
      }

      &::-ms-thumb {
        @include thumb;
        margin-top: 0;
        z-index: 2;
        opacity: 1;
      }
    }
  }

  .range-labels {
    @include flexbox();
    @include flex-flow(row nowrap);
    @include justify-content(space-around);
    @include align-items(flex-end);
    margin: 0 0 25px;
    padding: 0;
    list-style: none;
    width: 100%;

    li {
      @include flexbox();
      @include flex-flow(row nowrap);
      @include justify-content(center);
      @include align-items(center);
      @include transition(all 400ms);
      position: relative;
      // width: 20%;
      cursor: pointer;

      &.light {
        &::after {
          background: #fff;
        }

        p {
          color: #000;
        }
      }

      &.dark {
        &::after {
          background: #900c3f;
        }

        p {
          color: #fff;
        }
      }

      &:hover {
        a {
          opacity: 1;
          visibility: visible;
        }
      }

      &::after {
        @include box-shadow(0, 0, 0, #000, 3px);
        @include transition(all 400ms);
        position: absolute;
        bottom: -34px;
        z-index: 1;
        right: 0;
        left: 0;
        content: "";
        margin: 0 auto;
        width: 13px;
        height: 13px;
        border-radius: 50%;
        pointer-events: none;
      }

      &.active {
        p {
          font-weight: 700;
        }

        &::after {
          @include box-shadow(0, 0, 0, #000, 6px);
        }

        i {
          font-size: 18px;
          bottom: -80px;
        }
      }

      p {
        @include font-styles("Open Sans", 1em, false, 500, 1.1);
        text-align: center;
        max-width: 100%;
        margin: 0;
        word-wrap: break-word;
        position: relative;
      }

      i {
        @include transition(all 400ms);
        text-align: center;
        position: absolute;
        margin: 0 auto;
        bottom: -75px;
        font-size: 15px;
      }
    }
  }
}
</style>
