<template>
  <!--begin::form-->
  <b-form class="form" @submit.stop.prevent="save(form)">
    <b-row>
      <b-col
        :xl="
          field.type !== 'slider' &&
          field.type !== 'radio' &&
          field.type !== 'table'
            ? 6
            : 12
        "
        :class="field.clear ? 'mr-1' : ''"
        v-for="field in groupFields(group.fields)"
        :key="field.name"
      >
        <b-form-group
          style="position: relative"
          :state="hasError(field, true)"
          :invalid-feedback="hasError(field)"
          :label="
            field.hidden || field.type === 'radio'
              ? ''
              : $t(`campaigns.form.${field.name}.title`) +
                ` ${field.required ? '*' : ''}`
          "
          :label-for="field.name"
          :description="
            field.hidden || field.type === 'radio'
              ? ''
              : $t(`campaigns.form.${field.name}.description`)
          "
        >
          <div
            v-if="
              field.preview &&
                form[field.name] !== null &&
                form[field.name] !== ''
            "
          >
            <a :href="form[field.name].url" target="_blank">
              <i class="flaticon-eye mr-2 mb-2"></i> {{ $t("tables.preview") }}
            </a>
          </div>
          <!--begin::slider-->
          <b-row v-if="field.type === 'slider'">
            <b-col cols="12">
              <slider
                :rangeData="range"
                :id="field.name"
                theme="light"
                @changed="changeValue"
              />
            </b-col>
          </b-row>
          <!--end::slider-->
          <!--begin::multiselect-->
          <multiselect
            v-else-if="field.type === 'select'"
            :id="field.name"
            v-model="form[field.name]"
            :options="
              field.options
                ? field.options.length > 0
                  ? field.options
                  : []
                : []
            "
            :required="field.required"
            :searchable="true"
            :closeOnSelect="!field.multiple"
            label="text"
            trackBy="value"
            :value="field.default ? field.default : {}"
            :multiple="field.multiple || false"
            :selectLabel="$t(`campaigns.form.default.select`)"
            :show-labels="field.multiple || false"
            :deselectLabel="$t(`campaigns.form.default.deselect`)"
            :selectedLabel="$t(`campaigns.form.default.selected`)"
            :placeholder="$t(`campaigns.form.${field.name}.placeholder`)"
            :style="field.multiple ? 'width: 80%;display: inline-block;' : ''"
            @input="getCampaignTemplate(field)"
          >
            <template slot="option" slot-scope="props">
              <div class="option__desc">
                <span class="option__title" v-html="props.option.text" />
              </div>
            </template>
          </multiselect>
          <!--end::multiselect-->
          <!--begin::datetime-->
          <b-row v-else-if="field.type === 'datetime'">
            <b-col lg="6">
              <b-form-datepicker
                class="form-control"
                :id="`${field.name}_date`"
                :required="field.required"
                v-model="form[`${field.name}_date`]"
                today-button
                reset-button
                :v-bind="$t(`campaigns.form.labels`) || {}"
                :start-weekday="1"
                close-button
                :locale="locale"
                :min="today"
                :state="field.validation"
              />
            </b-col>
            <b-col lg="6">
              <b-form-timepicker
                class="form-control"
                :minutes-step="15"
                :id="`${field.name}_time`"
                :required="field.required"
                v-model="form[`${field.name}_time`]"
                now-button
                reset-button
                :v-bind="$t(`campaigns.form.labels`) || {}"
                :start-weekday="1"
                close-button
                :locale="locale"
                :state="field.validation"
              />
            </b-col>
          </b-row>
          <!--begin::datetime-->
          <!--begin::text input-->
          <h3
            v-else-if="field.type === 'template_info'"
            :id="field.name"
            class="text-primary"
          >
            <span v-if="field.formatter">
              {{ field.formatter(form[field.name]) }}
            </span>
            <span v-else>
              {{ form[field.name] ? form[field.name] : "-" }}
            </span>
          </h3>
          <!--end::text input-->
          <!--begin::number input-->
          <b-input
            v-else-if="field.type === 'number'"
            :id="field.name"
            :required="field.required"
            v-model="form[field.name]"
            :min="field.min || 0"
            :placeholder="$t(`campaigns.form.${field.name}.placeholder`)"
            type="number"
          />
          <!--end::number input-->
          <!--begin::switch input-->
          <div
            v-else-if="field.type === 'boolean'"
            class="d-flex justify-content-start"
          >
            <toggle-button
              class="select d-flex align-items-center"
              :id="field.name"
              :color="field.color"
              v-model="form[field.name]"
              :value="form[field.name]"
              :width="field.width"
              :required="field.required"
              :labels="{
                checked: field.options.checked,
                unchecked: field.options.unchecked
              }"
            />
          </div>
          <!--end::switch input-->
          <!--begin::tempalte list-->
          <b-row v-else-if="field.type === 'table'">
            <b-col cols="12">
              <templates-list
                :items="field.options"
                :levels="levels"
                :categories="categories"
                :optionSelected="optionSelected"
              ></templates-list>
            </b-col>
          </b-row>
          <!--end::template list-->
          <!--begin::radio button-->
          <b-col
            cols="12"
            v-else-if="field.type === 'radio'"
            class="mb-8 text-center"
          >
            <b-button
              v-for="option in field.options"
              :key="option.value"
              class="font-weight-bold mx-4"
              size="md"
              :variant="
                form[field.name] === option.value ? 'primary' : 'light-success'
              "
              pill
              @click="changeTemplateOption(field, option)"
            >
              {{ option.text }}
            </b-button>
          </b-col>
          <!--end::radio button-->
          <!--begin::input-->
          <b-form-input
            v-else
            autocomplete="off"
            :id="field.name"
            :required="field.required"
            v-model="form[field.name]"
            :placeholder="$t(`campaigns.form.${field.name}.placeholder`)"
            :hidden="field.hidden ? true : false"
            :state="
              hasError(field, true) === false
                ? false
                : validIsEmpty(form[field.name])
                ? null
                : field.maxLength
                ? maxLengthError(form[field.name], field.maxLength)
                : field.validation
            "
          ></b-form-input>
          <b-form-invalid-feedback
            :id="`${field.name}-feedback`"
            v-if="hasError(field, true) !== false"
          >
            <span
              v-if="
                field.maxLength &&
                  !maxLengthError(form[field.name], field.maxLength)
              "
            >
              {{ callGetError("max_length", { length: field.maxLength }) }}
            </span>
            <span v-else>
              {{ callGetError("format") }}
            </span>
          </b-form-invalid-feedback>
          <!--end::input-->
          <!--begin::add all button-->
          <b-button
            v-if="field.multiple"
            class="py-3 btn-hover-dark font-weight-bold"
            variant="secondary"
            @click="form[field.name] = field.options"
            style="
              width: 15%;
              right: 0;
              display: inline-block;
              position: absolute;
              margin-top: 0;
            "
          >
            {{ $t("campaigns.form.addAll") }}
          </b-button>
          <!--end::add all button-->
        </b-form-group>
      </b-col>
      <!--begin::send or next step-->
      <!--begin::send button-->
      <b-col cols="12" v-if="group.sendButton">
        <span id="send-button">
          <b-button
            style="white-space: nowrap; text-align: center;"
            type="submit"
            variant="primary"
            size="lg"
            class="font-weight-bolder"
            :disabled="checkSaveDisabled || isSubmitting"
          >
            {{ $t("campaigns.form.save") }}
          </b-button>
        </span>

        <b-tooltip v-if="checkSaveDisabled" target="send-button">
          {{ $t("campaigns.form.save_disabled") }}
        </b-tooltip>
      </b-col>
      <!--end::send button-->
      <!--begin::next step button-->
      <b-col
        cols="6"
        class="mt-4"
        v-else-if="!group.sendButton && actualStep === group.step"
      >
        <b-button
          style="white-space: nowrap; text-align: center;"
          variant="primary"
          size="lg"
          class="font-weight-bolder"
          @click="nextStep"
        >
          {{ $t("campaigns.form.next") }}
        </b-button>
      </b-col>
      <!--end:: next step button-->
    </b-row>
  </b-form>
  <!--end::form-->
</template>

<script>
import _ from "lodash";
import moment from "moment-timezone";
import { getTemplate } from "@/api/controller/campaigns.api";

import Multiselect from "vue-multiselect";
import Slider from "@/components/form/Slider.component";
import TemplatesList from "./TemplatesList.component.vue";
import { ToggleButton } from "vue-js-toggle-button";

import { getError } from "@/utils/helpers";
import { validate, validMaxLength } from "@/utils/validators";

export default {
  name: "CreateCampaignForm",
  data() {
    return {
      optionSelected: "",
      locale: localStorage.getItem("language")
    };
  },
  components: {
    Multiselect,
    Slider,
    TemplatesList,
    ToggleButton
  },
  props: {
    actualStep: {
      type: Number,
      required: true
    },
    group: {
      type: Object,
      required: true
    },
    save: {
      type: Function,
      required: true
    },
    mustShowField: {
      type: Function,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    levels: {
      type: Array,
      required: false
    },
    categories: {
      type: Array,
      required: false
    },
    error: {
      required: false,
      default: false
    },
    errors: {
      required: false,
      type: Array
    },
    isSubmitting: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  computed: {
    checkSaveDisabled() {
      const result =
        String(this.form.schedule_type) != "automatic" &&
        !this.form.campaignTypeId;

      return result;
    },
    today() {
      return moment()
        .tz(this.form.timezone.value)
        .format();
    },
    range() {
      const list = [
        {
          name: this.$tc("campaigns.form.slider.week"),
          color: "#900c3f",
          value: "+1week"
        },
        {
          name: this.$tc("campaigns.form.slider.fifteen"),
          color: "#c70039",
          value: "+15days"
        },
        {
          name: this.$tc("campaigns.form.slider.monthly"),
          color: "#ff5733",
          value: "+1month"
        },
        {
          name: this.$tc("campaigns.form.slider.bimonth"),
          color: "#ffc300",
          value: "+2months"
        },
        {
          name: this.$tc("campaigns.form.slider.trimonth"),
          color: "#ea168e",
          value: "+3months"
        }
      ];

      return list;
    }
  },
  methods: {
    validIsEmpty(text) {
      return _.isEmpty(text);
    },
    maxLengthError(value, length) {
      return validMaxLength(value, length);
    },
    callGetError(code, params) {
      return getError(code, params);
    },
    changeTemplateOption(field, option) {
      this.optionSelected = option.text;
      this.form[field.name] = option.value;
      this.form.domain = "";
      this.form.landing = "";
      this.form.attachment = "";
      this.form.campaignTypeId = null;
      this.form.campaignName = "";
      this.form.url = "";
      this.form.sender = "";
      this.form.headers = "";
      this.$emit("updateLandingTemplate", "");
      this.$emit("updateMailTemplate", "");
      this.$emit("updatePostLandingTemplate", "");
    },
    getCampaignTemplate(field) {
      const stakeholderId = localStorage.getItem("managedCompanyId");
      let template = false;

      if (field.name === "campaignTypeId") {
        let form = _.clone(this.form);
        if (form.campaignTypeId) {
          getTemplate(stakeholderId, form.campaignTypeId.value).then(
            response => {
              this.$emit(
                "updateLandingTemplate",
                atob(response.data.records.landing.html)
              );
              this.$emit(
                "updateMailTemplate",
                atob(response.data.records.mail.html)
              );
              this.$emit(
                "updatePostLandingTemplate",
                atob(response.data.records.postlanding.html)
              );
            }
          );
        }
      }

      return template;
    },
    nextStep() {
      var self = this;
      var visibleFields = _.filter(
        this.fields,
        group => group.step <= this.actualStep
      );
      visibleFields = _.each(
        visibleFields,
        group =>
          (group.fields = _.filter(group.fields, field =>
            self.mustShowField(field)
          ))
      );
      var errors = { valid: true };
      if (visibleFields.length > 0) {
        errors = validate(visibleFields, this.form);
      }
      if (errors.valid) {
        this.$emit("updateActualStep", this.actualStep + 1);
      } else {
        this.errors = errors.errors;
      }
    },
    groupFields(fields) {
      return _.filter(fields, field => this.mustShowField(field));
    },
    changeValue(value, field) {
      this.form[field] = value;
    },
    hasError(field, boolean = false) {
      let result = null;
      let finder = _.find(this.errors, error => error.name === field.name);
      let found = !_.isEmpty(finder);

      if (found) {
        if (boolean) {
          result = false;
        } else if (!finder.error) {
          let multiple = 1;
          if (field.type === "datetime") multiple = 2;
          if (field.type === "number" && this.form[field.name] < field.min) {
            result = this.callGetError("min_number", {
              field: _.toLower(
                this.$t(
                  `campaigns.form.${
                    _.find(this.errors, error => error.name === field.name).name
                  }.title`
                )
              ),
              value: field.min
            });
          } else {
            result = this.callGetError(
              "is_required",
              {
                field: _.toLower(
                  this.$t(
                    `campaigns.form.${
                      _.find(this.errors, error => error.name === field.name)
                        .name
                    }.title`
                  )
                )
              },
              multiple
            );
          }
        } else {
          result = this.callGetError(finder.error, {
            field: this.$t(
              `campaigns.form.${
                _.find(this.errors, error => error.name === field.name).name
              }.title`
            ),
            checker: this.$t(
              `campaigns.form.${
                _.find(this.errors, error => error.name === field.name).checker
              }.title`
            )
          });
        }
      }

      return result;
    }
  }
};
</script>
