<template>
  <div id="templates-list">
    <div class="d-flex">
      <!--filters::begin-->
      <div class="d-flex">
        <!--ddlevels::begin-->
        <b-dropdown
          v-if="levels"
          id="ddown-levels"
          :text="$t(`tables.level`)"
          class="mr-6"
        >
          <div
            v-for="(level, index) in levels"
            :key="level.text + index"
            class="dropdown-item bg-white text-body mr-6"
          >
            <b-form-checkbox
              @change="filter(level.text, 'levels')"
              :value="level.text"
              >{{ level.text }}</b-form-checkbox
            >
          </div>
        </b-dropdown>
        <!--ddlevels::end-->
        <!--ddcategories::begin-->
        <b-dropdown
          v-if="categories"
          id="ddown-categories"
          :text="$t(`tables.category`)"
          class="mr-6"
        >
          <div
            v-for="(category, index) in categories"
            :key="category.text + index"
            class="dropdown-item bg-white text-body mr-6"
          >
            <b-form-checkbox
              @change="filter(category.text, 'categories')"
              :value="category.text"
              >{{ category.text }}</b-form-checkbox
            >
          </div>
        </b-dropdown>
        <!--ddcategories::families-->
      </div>
      <!--filters::end-->
      <!--begin::Search Form-->
      <div class="d-flex align-items-center" id="kt_subheader_search">
        <span class="text-dark-50 font-weight-bold" id="kt_subheader_total"
          >{{ total }}
          {{
            $t("tables.total", {
              module: $tc("tables.campaigns", total).toLowerCase()
            })
          }}</span
        >
        <div class="ml-5">
          <div
            class="input-group input-group-sm input-group-solid"
            style="max-width: 175px"
          >
            <input
              type="text"
              class="form-control"
              id="search"
              v-model="search"
              :placeholder="$t('tables.searchByName') + ' ...'"
            />
            <div class="input-group-append">
              <span class="input-group-text">
                <span class="svg-icon">
                  <!--begin::Svg Icon | path:/metronic/theme/html/demo9/dist/assets/media/svg/icons/General/Search.svg-->
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><g fill="none" fill-rule="evenodd"><path d="M0 0h24v24H0z"/><path d="M14.293 16.707a1 1 0 011.414-1.414l4 4a1 1 0 01-1.414 1.414l-4-4z" fill="#000" fill-rule="nonzero" opacity=".3"/><path d="M11 16a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 110-14 7 7 0 010 14z" fill="#000" fill-rule="nonzero"/></g></svg>
                  <!--end::Svg Icon-->
                </span>
                <!--<i class="flaticon2-search-1 icon-sm"></i>-->
              </span>
            </div>
          </div>
        </div>
      </div>
      <!--end::Search Form-->
    </div>

    <b-table
      v-if="items.length"
      hover
      :fields="fields"
      :items="filtered"
      responsive="sm"
      stacked="sm"
      small
      @row-clicked="callOpenTemplate"
      :per-page="perPage"
      :current-page="currentPage"
      show-empty
      selectable
      select-mode="single"
      ref="templatesTable"
      no-select-on-click
    >
      <template #cell(actions)="row">
        <b-button
          :v-b-modal="`modal-template-preview-${row.index}`"
          pill
          variant="light-primary"
          class="mr-2 px-auto"
          :ref="`btn-${row.index}`"
          @click="openTemplatePreview(row.item, row.index)"
        >
          {{ $t("tables.preview") }}
        </b-button>
        <template-preview
          :id="row.index"
          :item="row.item"
          @select-row="selectRowTable"
        ></template-preview>
      </template>
    </b-table>
    <div class="flex-wrap mx-auto" v-if="total > 0">
      <b-pagination
        class="d-flex flex-wrap justify-content-center align-items-center mt-3 py-2 mr-3"
        v-model="currentPage"
        :total-rows="total"
        :per-page="perPage"
        aria-controls="companies-table"
        first-number
        pill
        last-number
        size="lg"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import TemplatePreview from "./TemplatePreview.component";

export default {
  name: "TemplatesList",
  data() {
    return {
      search: "",
      perPage: 10,
      currentPage: 1,
      activeFilters: {
        levels: [],
        categories: []
      },
      selectedRow: -1
    };
  },
  props: {
    items: {
      type: Array,
      required: true
    },
    levels: {
      type: Array,
      required: true
    },
    categories: {
      type: Array,
      required: true
    },
    optionSelected: {
      type: String,
      required: true
    }
  },
  components: {
    TemplatePreview
  },
  watch: {
    optionSelected() {
      this.activeFilters.levels = [];
      this.activeFilters.categories = [];
      this.search = "";
    }
  },
  computed: {
    total() {
      return this.filtered.length;
    },
    filtered() {
      let templates = this.items;

      if (this.search !== "") {
        const regex = new RegExp(`\\b${_.deburr(this.search)}`, "i");
        templates = _.filter(templates, template => {
          return regex.test(_.deburr(template.campaignName));
        });
      }
      if (this.activeFilters.levels.length > 0) {
        templates = _.filter(templates, template =>
          _.includes(this.activeFilters.levels, template.campaignLevel)
        );
      }
      if (this.activeFilters.categories.length > 0) {
        templates = _.filter(templates, template =>
          _.includes(this.activeFilters.categories, template.campaignCategory)
        );
      }
      return templates;
    },
    fields() {
      return [
        {
          key: "campaignCategory",
          label: this.$tc("tables.category", 1),
          sortable: false,
          sortByFormatted: false,
          class: "align-middle text-left"
        },
        {
          key: "campaignName",
          label: this.$tc("tables.name", 1),
          sortable: false,
          sortByFormatted: false,
          class: "align-middle text-left"
        },
        {
          key: "campaignLevel",
          label: this.$tc("tables.level", 1),
          sortable: false,
          sortByFormatted: false,
          class: "align-middle text-left"
        },
        {
          key: "actions",
          label: this.$tc("tables.actions", 1),
          sortable: false,
          sortByFormatted: false,
          class: "align-middle text-left"
        }
      ];
    }
  },
  methods: {
    callOpenTemplate(row, index) {
      this.openTemplatePreview("", index);
      this.$refs.templatesTable.unselectRow(index);
    },
    filter(text, type) {
      _.includes(this.activeFilters[type], text)
        ? (this.activeFilters[type] = _.reject(
            this.activeFilters[type],
            el => el === text
          ))
        : this.activeFilters[type].push(text);
    },
    selectRowTable(index) {
      if (this.selectedRow != -1) {
        this.$refs.templatesTable.unselectRow(this.selectedRow);
      }
      this.$refs.templatesTable.selectRow(index);
      this.selectedRow = index;
    },
    openTemplatePreview(item, index) {
      this.$root.$emit(
        "bv::show::modal",
        `modal-template-preview-${index}`,
        `btn-${index}`
      );
    }
  }
};
</script>

<style>
tr {
  cursor: pointer;
}

.table.b-table > tbody > .table-active,
.table.b-table > tbody > .table-active > th,
.table.b-table > tbody > .table-active > td {
  background-color: rgba(11, 183, 131, 0.18) !important;
}
</style>
